import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, take} from 'rxjs';
import { environment } from 'src/environments/environment';
import {CardDescriptor, PaymentCard, ProfilePaymentsResponce} from '../model/payments';
import {LazyData} from "../model/data";
import {GetRequest} from "../api/methods";
import {UserRepositoryService} from "../repository/user-repository.service";

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  private gateUrl = environment.paymentsUrl + '/payments';
  profileCards =  new BehaviorSubject<PaymentCard[]>([]);

  constructor( private http: HttpClient) { }

  public getProfileCards(): LazyData<PaymentCard[]> {
    return {
      cache: this.profileCards.value,
      future: () => {
        return this.getCards().then((data: ProfilePaymentsResponce) => {
          this.profileCards.next(data.paymentProfiles);
          return data.paymentProfiles;
        });
      }
    };
  }

  private async getCards(): Promise<ProfilePaymentsResponce> {
    const request = new GetRequest(this.http, `${this.gateUrl}/profile`);
    return await request.getResponse<ProfilePaymentsResponce>();
  }

  public addCard(body: CardDescriptor): Observable<any> {
    return this.http.post<any>(`${this.gateUrl}/cards/add-card`, body, { withCredentials: true }).pipe(take(1));
  }

  public removeCard(cardId: {id: string}): Observable<any> {
    return this.http.delete<any>(`${this.gateUrl}/cards`, {
      withCredentials: true,
      body: cardId
    }).pipe(take(1));
  }

  clear() {
    this.profileCards.next([]);
  }
}
